import { Select } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateLocation } from "../app/orderSlice";
import { Location } from "../interfaces";
import { isLocationOpen } from "./isLocationOpen";

interface LocationSelectInput {
  locations: Location[];
}

const LocationSelect: FC<LocationSelectInput> = ({ locations }) => {
  const dispatch = useDispatch();
  const [locationId, setLocationId] = useState<number>();
  const order = useSelector((state: any) => state.order);

  const updateLocationState = () => {
    const selectedLocation = locations.find((l) => l.id === locationId);
    if (!selectedLocation) return;

    dispatch(updateLocation({ value: selectedLocation }));
  };

  useEffect(() => {
    updateLocationState();
  }, [locationId]);

  return (
    <>
      <Select
        backgroundColor="white"
        borderColor="sumthindumplin.red"
        borderWidth="1.5px"
        rounded="xl"
        value={order?.location?.id}
        placeholder="Select option"
        onChange={(e) => {
          setLocationId(+e.target.value);
        }}
        _focus={{
          outline: "none",
        }}
        _hover={{
          outline: "none",
        }}
      >
        {locations.map((location, i) => {
          const isOpen = isLocationOpen(location);

          if (location.printerId)
            return (
              <option value={location.id} disabled={!isOpen}>
                {location.name} {!isOpen && " - CLOSED"}
              </option>
            );
        })}
      </Select>
    </>
  );
};

export default LocationSelect;
