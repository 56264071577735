import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Box, Button, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { clearOrder } from "../app/orderSlice";
//import { ReactComponent as DumplingMascotDead } from "../images/dumpling-mascot-unalived.svg";
//import { ReactComponent as DumplingMascot } from "../images/dumpling-mascot.svg";
import BaseLayout from "../layouts/BaseLayout";

const SplashPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const status = query.get("status");

  useEffect(() => {
    if (status === "success") dispatch(clearOrder());
  }, [status]);

  return (
    <BaseLayout>
      <Box
        height="100%"
        display="flex"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        mx="auto"
        my="15vh"
        textAlign="center"
        gap="3"
      >
        {/*status === "success" ? (
          <DumplingMascot width="120" />
        ) : (
          <DumplingMascotDead width="240" />
        )*/}
        <Text fontSize="24px" maxW="80">
          {status === "success" ? (
            <Box maxW="60">
              <Text>Thank you for ordering with us!</Text>
            </Box>
          ) : (
            <Box>
              <Text>There was an error processing your payment...</Text>
              <Text fontSize="16px" my="2">
                Your order has not been sent
              </Text>
            </Box>
          )}
        </Text>
        <Button
          variant="accent"
          pl="1.5"
          onClick={() => {
            if (status === "success") {
              window.location.href = "https://sumthindumplin.co.nz/";
            } else {
              navigate("/cart");
            }
          }}
        >
          <ChevronLeftIcon mr="auto" mt="0.5" h="5" w="6" />
          {status === "success" ? "Back to website" : "Back to cart"}
        </Button>
      </Box>
    </BaseLayout>
  );
};

export default SplashPage;
