import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Box, Collapse, Textarea } from "@chakra-ui/react";
import { FC, useState } from "react";
import { CartProduct } from "../../interfaces";

interface ProductNotesInput {
  cartProductState: CartProduct;
  updateCartProductState: (field: string, value: string | number) => void;
}

const ProductNotes: FC<ProductNotesInput> = ({
  cartProductState,
  updateCartProductState,
}) => {
  const [isNotesOpen, setIsNotesOpen] = useState(false);

  const toggleNotesOpen = () => {
    setIsNotesOpen(!isNotesOpen);
  };

  const NotesHeader = () => {
    return (
      <Box
        fontWeight="bold"
        display="flex"
        borderBottomWidth={!isNotesOpen ? "1.5px" : "none"}
        borderColor="sumthindumplin.red"
        pb={!isNotesOpen ? "1" : "none"}
      >
        Notes
        <Box ml="auto">
          {isNotesOpen ? (
            <ChevronUpIcon onClick={() => toggleNotesOpen()} />
          ) : (
            <ChevronDownIcon onClick={() => toggleNotesOpen()} />
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box my="1">
      <NotesHeader />
      <Collapse in={isNotesOpen}>
        <Textarea
          value={cartProductState.notes ?? ""}
          onChange={(e) => updateCartProductState("notes", e.target.value)}
          placeholder="Type your notes here..."
          fontSize="sm"
          my="1"
          bgColor="white"
          border="none"
        />
      </Collapse>
    </Box>
  );
};

export default ProductNotes;
