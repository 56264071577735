import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Product } from "../interfaces";
import DisplayProducts from "./Products/DisplayProducts";

interface AddonPromptPopup {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  products: Product[];
}

const AddonPromptPopup: FC<AddonPromptPopup> = ({
  isOpen,
  onOpen,
  onClose,
  products,
}) => {
  const navigate = useNavigate();

  const drinks = products.filter((p) => p.category !== "Drinks");
  const sides = products.filter((p) => p.category !== "Food");

  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent my="auto" mx="5" backgroundColor="sumthindumplin.pink">
          <ModalHeader ml="auto" mr="auto" mt="6" fontSize="2xl">
            Add more items?
          </ModalHeader>
          <ModalBody textAlign="center" fontSize="20px" overflowY="hidden">
            Sides
            <Box
              maxH="30vh"
              overflowY="auto"
              sx={{
                "::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <DisplayProducts products={drinks} onlyAddons />
            </Box>
            Drinks
            <Box
              maxH="30vh"
              overflowY="auto"
              sx={{
                "::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <DisplayProducts products={sides} onlyAddons />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" pl="1.5" onClick={onClose} mr="auto">
              <ChevronLeftIcon mr="auto" mt="0.5" h="5" w="6" />
              Back
            </Button>
            <Button
              variant="accent"
              pr="1.5"
              onClick={() => navigate("/customer-details")}
            >
              Continue
              <ChevronRightIcon ml="auto" mt="0.5" h="5" w="6" />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddonPromptPopup;
