import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Text, useDisclosure } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CartProduct, Product } from '../interfaces';
import ProductDetails from './Products/ProductDetails';

interface ProductPreviewInput {
  product: Product;
  previewVariation?: 'regular' | 'cart' | 'addonPopup';
}

export interface ProductDetailsInput extends ProductPreviewInput {
  cartProductState: CartProduct;
  resetCartProductState: () => void;
  updateCartProductState: (field: string, value: string | number) => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const ProductPreview: FC<ProductPreviewInput> = ({ product, previewVariation }) => {
  const cart = useSelector((state: any) => state.order.cart);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { name, price, description, image } = product;
  const defaultLocalCartProductState = {
    initialPrice: product.price,
    quantity: 1,
    notes: undefined,
    ...product
  };

  // Locally create and store cart product instance until the product is added to the cart
  const [cartProductState, setCartProductState] = useState<CartProduct>(defaultLocalCartProductState);

  // Keeps local cart state in sync with product in cart if the item is being viewed on cart page
  useEffect(() => {
    if (previewVariation === 'cart') {
      let existingProductInCart = cart.find(
        (product: CartProduct) => product.cartItemId === cartProductState.cartItemId
      );
      if (existingProductInCart) setCartProductState(existingProductInCart);
    }
  }, [cart]);

  const updateCartProductState = (field: string, value: any) => {
    console.log('HERE', cartProductState, field, value);
    // Handle automatically updated the pricing increase from the different serving sizes (+$5 if its large)
    let delta = 0;
    let extra = 0;
    switch (cartProductState?.servingSize) {
      case 'large':
        delta = 5;
        break;
      case 'medium':
        delta = 3;
        break;
      default:
        delta = 0;
        break;
    }

    if (field === 'servingSize') {
      switch (value) {
        case 'large':
          delta = 5;
          break;
        case 'medium':
          delta = 3;
          break;
        default:
          delta = 0;
          break;
      }
    }

    if (cartProductState?.extraSauce) {
      extra = 1.2;
    }

    if (field === 'extraSauce') {
      extra = value === 'none' ? 0 : 1.2;
    }

    setCartProductState({
      ...cartProductState,
      [field]: value === 'none' ? undefined : value,
      price: cartProductState.initialPrice + delta + extra
    });
  };

  const resetCartProductState = () => {
    setCartProductState(defaultLocalCartProductState);
  };

  const resolveOnClick = () => {
    if (!isOpen) {
      onOpen();
    } else {
      onClose();
    }
  };

  return (
    <Box
      p="3"
      my={previewVariation === 'cart' ? '2' : '4'}
      backgroundColor="sumthindumplin.pink"
      borderColor="sumthindumplin.red"
      borderWidth="1.5px"
      rounded="xl"
    >
      <Box display="flex">
        <Box alignSelf="flex-start" maxWidth="114px">
          {previewVariation === 'cart' && (
            <Box
              color="white"
              position="absolute"
              my="-2"
              mx="-2"
              backgroundColor="sumthindumplin.red"
              w="5"
              h="5"
              rounded="full"
              textAlign="center"
              fontSize="xs"
              fontWeight="bold"
              border="1px"
            >
              {cartProductState.quantity}
            </Box>
          )}
          <img
            alt="product"
            style={{
              borderRadius: '8px'
            }}
            src={`${process.env.REACT_APP_STRAPI_URL?.slice(0, -1)}${image?.data?.attributes?.url}`}
          />
        </Box>
        <Box display="flex" flexDir="column" textAlign="left" w="full" pl="3" onClick={resolveOnClick}>
          <Text fontFamily="HelveticaNowDisplay-ExtraBold" fontSize="17">
            {name.toUpperCase()}
          </Text>
          <Box fontSize="12px" fontWeight="600">
            ${price.toFixed(2)}
          </Box>
          <Text fontSize="12px">{description}</Text>
        </Box>
        <Box position="relative">
          {isOpen && (
            <ChevronUpIcon
              color="sumthindumplin.red"
              position="absolute"
              right="0"
              backgroundColor="white"
              rounded="xl"
              onClick={onClose}
              h="5"
              w="5"
            />
          )}
        </Box>
        {!isOpen && (
          <Box mt="auto" w={5} ml="auto">
            <ChevronDownIcon
              color="white"
              backgroundColor="sumthindumplin.red"
              rounded="full"
              w={5}
              h={5}
              onClick={onOpen}
            />
          </Box>
        )}
      </Box>
      <Box>
        <ProductDetails
          previewVariation={previewVariation}
          product={product}
          cartProductState={cartProductState}
          updateCartProductState={updateCartProductState}
          resetCartProductState={resetCartProductState}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
      </Box>
    </Box>
  );
};

export default ProductPreview;
