import {
  Box,
  CloseButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import { ReactComponent as AppleShare } from "../images/apple-share.svg";

const IosInstructionsPrompt = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const cookies = new Cookies();
  const cookieExpiryDate = new Date();
  cookieExpiryDate.setMonth(cookieExpiryDate.getMonth() + 1);

  const isSafari = () => {
    return (
      navigator.userAgent &&
      navigator.userAgent.indexOf("CriOS") == -1 &&
      navigator.userAgent.indexOf("FxiOS") == -1 &&
      navigator.userAgent.indexOf("Opera") == -1
    );
  };

  const isInStandaloneMode = () =>
    "standalone" in window.navigator && window.navigator.standalone;

  useEffect(() => {
    onOpen();
  }, []);

  if (isInStandaloneMode() || !isSafari()) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        cookies.set("pwaPrompted", true, {
          path: "/",
          expires: cookieExpiryDate,
        });
        onClose();
      }}
      closeOnOverlayClick={true}
    >
      <ModalOverlay />
      <ModalContent
        position="fixed"
        bottom="-6"
        zIndex={40}
        w="95%"
        fontSize="md"
        rounded="lg"
        backgroundColor="sumthindumplin.pink"
        onClick={onClose}
      >
        <CloseButton position="absolute" right="0" />
        <Box px="5" pb="2" pt="3" zIndex={25}>
          <Text fontWeight="extrabold" mb="-1">
            To add this app on your apple device:
          </Text>
          <Box display="flex" width="100%" alignItems="center">
            tap <AppleShare display="inline" height="35" /> and then: "
            <Text as="span" fontWeight="extrabold">
              Add to homescreen
            </Text>
            "
          </Box>
        </Box>
        <Box
          zIndex={20}
          position="absolute"
          left="0"
          right="0"
          mx="auto"
          bottom="-2"
          rounded="sm"
          width="8"
          height="8"
          background="sumthindumplin.pink"
          p="3"
          transform="rotate(45deg)"
        />
      </ModalContent>
    </Modal>
  );
};

export default IosInstructionsPrompt;
