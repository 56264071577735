import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Box, Button } from "@chakra-ui/react";
import { FC } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Star } from "../images/star.svg";

interface CartFooterInput {
  handleSubmit: () => void;
}

const CartFooter: FC<CartFooterInput> = ({ handleSubmit }) => {
  const navigate = useNavigate();
  const cart = useSelector((state: any) => state.order.cart);

  return (
    <Box
      backgroundColor="sumthindumplin.darkpink"
      display="flex"
      justifyItems="center"
      alignItems="center"
      p="5"
      bottom="0"
      borderTopWidth="1.5px"
      borderTopColor="sumthindumplin.red"
      w="full"
      fontSize="md"
      position="fixed"
    >
      <Button
        variant="secondary"
        backgroundColor="white"
        pl="1.5"
        onClick={() => navigate("/customer-details")}
      >
        <ChevronLeftIcon mr="auto" mt="0.5" h="5" w="6" />
        Back
      </Button>
      <Button
        variant="accent"
        ml="auto"
        onClick={handleSubmit}
        isDisabled={!cart.length}
      >
        Purchase
        <Box pl="2">
          <Star height="17" width="17" color="white" />
        </Box>
      </Button>
    </Box>
  );
};

export default CartFooter;
