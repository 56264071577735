import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { Location } from "../interfaces";
import LocationSelect from "./LocationSelect";

interface LocationSelectInput {
  locations: Location[];
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const LocationSelectModal: FC<LocationSelectInput> = ({
  locations,
  isOpen,
  onOpen,
  onClose,
}) => {
  const order = useSelector((state: any) => state.order);

  useEffect(() => {
    if (!order.location) onOpen();
  }, []);

  return (
    <>
      {/* <Button onClick={onOpen}>Open Modal</Button> */}
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          my="auto"
          mx="5"
          overflowY="hidden"
          backgroundColor="sumthindumplin.pink"
        >
          <ModalHeader ml="auto" mr="auto" fontSize="2xl" mt="8">
            Choose your location
          </ModalHeader>
          <ModalBody>
            <LocationSelect locations={locations} />
          </ModalBody>
          <ModalFooter>
            <Button
              variant="accent"
              isDisabled={!order.location}
              pr="1.5"
              onClick={onClose}
            >
              Next
              <ChevronRightIcon
                ml="auto"
                mt="0.5"
                h="5"
                w="6"
                color={!order.location ? "sumthindumplin.gray.700" : "black"}
              />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LocationSelectModal;
