import {
  Box,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { updateLocation } from "../app/orderSlice";
import AddonPromptPopup from "../components/AddonPromptPopup";
import { isLocationOpen } from "../components/isLocationOpen";
import LocationSelectModal from "../components/LocationSelectModal";
//import MascotHint from "../components/MascotHint";
import OrderFooter from "../components/OrderFooter";
import DisplayProducts from "../components/Products/DisplayProducts";
import PwaPrompt from "../components/PwaPrompt";
import { ReactComponent as MapPin } from "../images/map-pin.svg";
import { Location } from "../interfaces";
import BaseLayout from "../layouts/BaseLayout";

const Order = () => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const order = useSelector((state: any) => state.order);
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAddonOpen,
    onOpen: onAddonOpen,
    onClose: onAddonClose,
  } = useDisclosure();

  const [tabIndex, setTabIndex] = useState(0);
  const tabOptions = ["Food", "Drinks", "Offers"];
  //const [showMascot, setShowMascot] = useState(false);

  const getLocations = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}strapi/locations`
    );
    return res?.data;
  };

  const getProducts = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}strapi/products`
    );
    return res?.data;
  };

  const handleScroll = () => {
    if (
      bodyRef.current &&
      bodyRef.current.getBoundingClientRect().bottom - 1 <= window.innerHeight
    ) {
     // setShowMascot(true);
    } else {
     // setShowMascot(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const {
    data: products,
    isLoading,
    error,
  } = useQuery(["products"], getProducts);

  const {
    data: locations,
    isLoading: isLocationsLoading,
    error: isLocationsError,
  } = useQuery(["locations"], getLocations);

  const syncLatestLocationData = () => {
    if (!order.location || !locations) return;

    const selectedLocation = locations.find(
      (l: Location) => l.id === order.location.id
    );

    if (!selectedLocation) return dispatch(updateLocation({ undefined }));

    if (!isLocationOpen(selectedLocation))
      return dispatch(updateLocation({ undefined }));

    return dispatch(updateLocation({ value: selectedLocation }));
  };

  useEffect(() => {
    syncLatestLocationData();
  }, [locations]);

  if (isLoading || isLocationsLoading)
    return (
      <BaseLayout>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          w="full"
          h="60vh"
        >
          <Spinner size="xl" />
        </Box>
      </BaseLayout>
    );
  if (error || isLocationsError)
    return (
      <Box>Error Loading Products/Locations - Online Ordering Disabled</Box>
    );

  return (
    <Box ref={bodyRef}>
      <PwaPrompt />
      <AddonPromptPopup
        products={products}
        isOpen={isAddonOpen}
        onOpen={onAddonOpen}
        onClose={onAddonClose}
      />
      <BaseLayout>
        <LocationSelectModal
          locations={locations}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
        <Tabs
          align="center"
          variant="soft-rounded"
          onChange={(index) => setTabIndex(index)}
          size="md"
        >
          <TabList ml="auto" mr="auto">
            {tabOptions.map((tabOption, i) => {
              return (
                <Tab
                  key={i}
                  height="10"
                  borderRadius="15px"
                  color="sumthindumplin.red"
                  backgroundColor="transparent"
                  outline="none"
                  _focus={{
                    outline: "none",
                  }}
                  _selected={{
                    color: "sumthindumplin.red",
                    fontWeight: "900",
                    bg: "transparent",
                    borderRadius: "15px",
                    textDecoration: "underline",
                    textUnderlineOffset: "4px",
                  }}
                >
                  <Box>
                    {tabOption}
                    <Box
                      mt="0.5"
                      w="1.5"
                      h="1.5"
                      rounded="full"
                      bgColor={
                        i === tabIndex ? "sumthindumplin.pink" : "transparent"
                      }
                      mx="auto"
                    />
                  </Box>
                </Tab>
              );
            })}
          </TabList>
          <Box
            _hover={{ cursor: "pointer" }}
            display="flex"
            mt="1"
            fontSize="sm"
            alignContent="center"
            justifyContent="center"
            onClick={() => onOpen()}
          >
            <MapPin
              width="14"
              height="14"
              style={{ marginTop: "4px" }}
              color="sumthindumplin.red"
            />
            <Text textDecoration="underline" mx="1">
              {order.location
                ? `Location: ${order.location?.name}, Click to change`
                : `No Location, Click to set`}
            </Text>
          </Box>
          <TabPanels>
            {[1, 2, 3, 4].map((i) => {
              return (
                <TabPanel key={i} p="0" pb="24">
                  {order.location && (
                    <DisplayProducts products={products} tabIndex={tabIndex} />
                  )}
                </TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </BaseLayout>
      {/* <MascotHint showMascot={showMascot} /> */}
      <OrderFooter onAddonOpen={onAddonOpen} />
    </Box>
  );
};

export default Order;
