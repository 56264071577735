import { Box, Button, Collapse, Select, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { addToCart, removeProduct, updateProductInCart } from '../../app/orderSlice';
import { SauceOptions } from '../../interfaces';
import { ProductDetailsInput } from '../ProductPreview';
import MobileNumberInput from './MobileNumberInput';
import ProductNotes from './ProductNotes';

const ProductDetails: FC<ProductDetailsInput> = ({
  previewVariation,
  cartProductState,
  updateCartProductState,
  resetCartProductState,
  product,
  isOpen,
  onClose
}) => {
  const dispatch = useDispatch();

  return (
    <Collapse in={isOpen} animateOpacity>
      <Box display="flex" flexDir="column" w="full" fontSize="sm" mt="1.5">
        {product.variationSelection && (
          <Box mx="1" textAlign="left">
            <Box display="flex" alignItems="center">
              <Text fontWeight="bold">
                Serving Size
                <Text as="span" color="sumthindumplin.pink">
                  {' '}
                  *
                </Text>
              </Text>
              <Select
                value={cartProductState.servingSize ?? 'null'}
                onChange={(e) => e.target.value !== 'null' && updateCartProductState('servingSize', e.target.value)}
                maxWidth="70%"
                h="7"
                ml="auto"
                size="sm"
                rounded="full"
                my="1"
                variant="filled"
                bgColor="white"
                _focus={{
                  bgColor: 'white',
                  outline: 'none'
                }}
                _hover={{ bgColor: 'white', outline: 'none' }}
              >
                <option value="null" disabled>
                  Select Option
                </option>
                <option value="regular">Regular (6 Pieces) - ${cartProductState.initialPrice.toFixed(2)}</option>
                <option value="medium">Medium (8 Pieces) - ${(cartProductState.initialPrice + 3).toFixed(2)}</option>
                <option value="large">Large (10 Pieces) - ${(cartProductState.initialPrice + 5).toFixed(2)}</option>
              </Select>
            </Box>
            <Box display="flex" alignItems="center">
              <Text fontWeight="bold">
                Sauce
                <Text as="span" color="sumthindumplin.pink">
                  {' '}
                  *
                </Text>
              </Text>
              <Select
                value={cartProductState.sauce ?? 'null'}
                onChange={(e) => e.target.value !== 'null' && updateCartProductState('sauce', e.target.value)}
                size="sm"
                h="7"
                maxWidth="70%"
                ml="auto"
                rounded="full"
                my="1"
                variant="filled"
                bgColor="white"
                _focus={{
                  bgColor: 'white',
                  outline: 'none'
                }}
                _hover={{ bgColor: 'white', outline: 'none' }}
              >
                <option value="null" disabled>
                  Select Option
                </option>
                {Object.keys(SauceOptions).map((key, i) => {
                  if (isNaN(+key))
                    return (
                      <option key={i} value={key}>
                        {key}
                      </option>
                    );
                })}
              </Select>
            </Box>
            <Box display="flex" alignItems="center">
              <Text fontWeight="bold">
                Extra Sauce?
                <Text as="span" color="sumthindumplin.pink">
                  {' '}
                  *
                </Text>
              </Text>
              <Select
                value={cartProductState.extraSauce ?? 'null'}
                onChange={(e) => e.target.value !== 'null' && updateCartProductState('extraSauce', e.target.value)}
                size="sm"
                h="7"
                maxWidth="70%"
                ml="auto"
                rounded="full"
                my="1"
                variant="filled"
                bgColor="white"
                _focus={{
                  bgColor: 'white',
                  outline: 'none'
                }}
                _hover={{ bgColor: 'white', outline: 'none' }}
              >
                <option value="none">None</option>
                {Object.keys(SauceOptions).map((key, i) => {
                  if (isNaN(+key))
                    return (
                      <option key={i} value={key}>
                        {key} + $1.20
                      </option>
                    );
                })}
              </Select>
            </Box>
          </Box>
        )}
        <Box display="flex" alignItems="center" mx="1">
          <Text fontWeight="bold">Quantity</Text>
          <MobileNumberInput cartProductState={cartProductState} updateCartProductState={updateCartProductState} />
        </Box>
        {product.variationSelection && (
          <Box mt="1" mx="1">
            <ProductNotes cartProductState={cartProductState} updateCartProductState={updateCartProductState} />
          </Box>
        )}
        <Box display="flex" gap="3" mt="3" ml="auto">
          {previewVariation === 'cart' && (
            <Button
              variant="secondary"
              w="28"
              size="sm"
              onClick={() => dispatch(removeProduct(cartProductState.cartItemId))}
            >
              Remove
            </Button>
          )}
          <Button
            variant="accent"
            w="28"
            size="sm"
            isDisabled={product.variationSelection && (!cartProductState.servingSize || !cartProductState.sauce)}
            onClick={() => {
              if (previewVariation === 'cart') {
                dispatch(updateProductInCart(cartProductState));
              } else {
                dispatch(addToCart(cartProductState));
              }
              onClose();
              resetCartProductState();
            }}
          >
            {previewVariation === 'cart' ? 'Update' : 'Add To Cart'}
          </Button>
        </Box>
      </Box>
    </Collapse>
  );
};

export default ProductDetails;
