import { defineStyle, defineStyleConfig, extendTheme } from "@chakra-ui/react";

const accent = defineStyle({
  backgroundColor: "sumthindumplin.red",
  color: "white",
  rounded: "xl",
  _disabled: {
    backgroundColor: "sumthindumplin.gray.500",
    color: "sumthindumplin.gray.700",
  },
});

const dark = defineStyle({
  rounded: "xl",
  backgroundColor: "black",
  color: "white",
  _hover: {},
});

const secondary = defineStyle({
  rounded: "xl",
  backgroundColor: "sumthindumplin.darkpink",
  // color: "sumthindumplin.gray.700",
  color: "sumthindumplin.reds",
  _hover: {},
});

const buttonTheme = defineStyleConfig({
  variants: { dark, accent, secondary },
});

const theme = extendTheme({
  components: { Button: buttonTheme },
  colors: {
    sumthindumplin: {
      gray: {
        200: "#F5F5F5",
        300: "#EAEAEA",
        400: "#e4e4e4",
        500: "#E7E7E7",
        700: "#7A7A7A",
      },
      red: "#DD3025",
      pink: "#FFECF1",
      darkpink: "#FFC2D1",
    },
  },
  fonts: {
    heading: `'HelveticaNowDisplay-ExtraBold', sans-serif`,
    body: `'Gilroy', sans-serif`,
  },
  styles: {
    global: () => ({
      body: {
        bg: "#FFECF1",
        color: "sumthindumplin.red",
      },
    }),
  },
});

export default theme;
