import { createSlice } from "@reduxjs/toolkit";
import { CartProduct, Order } from "../interfaces";

const initialCart: CartProduct[] = [];
const initialOrder: Order = {
  name: "",
  email: "",
  phone: "",
  pickupTime: "now",
  cart: initialCart,
  total: 0,
};

const orderSlice = createSlice({
  name: "order",
  initialState: {
    order: initialOrder,
  },
  reducers: {
    updateTotal: (state, action) => {
      state.order.total = action.payload.value;
    },

    updateLocation: (state, action) => {
      state.order.location = action.payload.value;
    },

    updateCustomer: (state, action) => {
      const field: "name" | "email" | "phone" | "pickupTime" =
        action.payload.customerDetailField;
      state.order[field] = action.payload.value;
    },

    addToCart: (state, action) => {
      const productInCart: CartProduct | undefined = state.order.cart.find(
        (product: CartProduct) => product.id === action.payload.id
      );

      // If the product is something like drinks/chips without variations then even if they "go back" and add another one it keeps it
      // in the same cart product instance
      if (productInCart && !action.payload.variationSelection) {
        const productIndex = state.order.cart.indexOf(productInCart);
        const updatedProduct: CartProduct = {
          ...productInCart,
          ...action.payload,
          quantity: productInCart.quantity + action.payload.quantity,
        };
        state.order.cart[productIndex] = updatedProduct;
      } else {
        state.order.cart.push({
          ...action.payload,
          cartItemId: state.order.cart.length + 1,
        });
      }
    },

    updateProductInCart: (state, action) => {
      const productInCart: CartProduct | undefined = state.order.cart.find(
        (product: CartProduct) =>
          product.cartItemId === action.payload.cartItemId
      );

      if (productInCart) {
        const productIndex = state.order.cart.indexOf(productInCart);
        const updatedProduct: CartProduct = {
          ...productInCart,
          ...action.payload,
        };
        state.order.cart[productIndex] = updatedProduct;
      }
    },

    incrementQuantity: (state, action) => {
      const productInCart: CartProduct | undefined = state.order.cart.find(
        (product) => product.id === action.payload
      );
      if (productInCart) productInCart.quantity++;
    },

    decrementQuantity: (state, action) => {
      const productInCart: CartProduct | undefined = state.order.cart.find(
        (product) => product.id === action.payload
      );
      if (productInCart && productInCart.quantity === 1) {
        productInCart.quantity = 1;
      } else if (productInCart) {
        productInCart.quantity--;
      }
    },

    removeProduct: (state, action) => {
      const removeProduct = state.order.cart.filter(
        (product) => product.cartItemId !== action.payload
      );
      state.order.cart = removeProduct;
    },

    removeAllProducts: (state) => {
      state.order.cart = [];
    },

    clearOrder: (state) => {
      state.order = initialOrder;
    },
  },
});

export const orderReducer = orderSlice.reducer;
export const {
  addToCart,
  updateProductInCart,
  incrementQuantity,
  decrementQuantity,
  removeProduct,
  removeAllProducts,
  updateCustomer,
  updateLocation,
  clearOrder,
  updateTotal,
} = orderSlice.actions;
