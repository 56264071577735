import { Box, HStack, Input, Text, useNumberInput } from "@chakra-ui/react";
import { FC } from "react";
import { CartProduct } from "../../interfaces";

interface UpdateCartProductInput {
  cartProductState: CartProduct;
  updateCartProductState: (field: string, value: string | number) => void;
}

const HookUsage: FC<UpdateCartProductInput> = ({
  cartProductState,
  updateCartProductState,
}) => {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: 1,
      min: 1,
      precision: 0,
      value: cartProductState.quantity,
      onChange: (valueAsString, valueAsNumber) => {
        updateCartProductState("quantity", valueAsNumber);
      },
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  return (
    <HStack>
      <Box px="5" pb="0.5" bgColor="transparent" rounded="full" {...dec}>
        <Text fontSize="lg">-</Text>
      </Box>
      <Input
        h="7"
        fontSize="sm"
        focusBorderColor="transparent"
        border="none"
        textAlign="center"
        {...input}
      />
      <Box px="4" pb="0.5" bgColor="transparent" rounded="full" {...inc}>
        <Text fontSize="md">+</Text>
      </Box>
    </HStack>
  );
};

const MobileNumberInput: FC<UpdateCartProductInput> = ({
  cartProductState,
  updateCartProductState,
}) => {
  return (
    <Box rounded="full" h="7" width="70%" ml="auto" my="1" bgColor="white">
      <HookUsage
        updateCartProductState={updateCartProductState}
        cartProductState={cartProductState}
      />
    </Box>
  );
};

export default MobileNumberInput;
