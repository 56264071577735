import { Location } from "../interfaces";

export const isLocationOpen = (location: Location) => {
  const date = new Date();
  const todayLocationHours = location.locationHours[date.getDay() === 0 ? 6 : date.getDay() - 1];

  if (
    !todayLocationHours ||
    !todayLocationHours.openFrom ||
    !todayLocationHours.openTo
  )
    return false;

  if (todayLocationHours.closed) return false;

  const locationOpenFrom = new Date();
  locationOpenFrom.setSeconds(0);
  locationOpenFrom.setHours(+todayLocationHours.openFrom.slice(0, 2));
  locationOpenFrom.setMinutes(+todayLocationHours.openFrom.slice(3, 5));

  const locationOpenTo = new Date();
  locationOpenTo.setSeconds(0);

  locationOpenTo.setHours(+todayLocationHours.openTo.slice(0, 2));
  locationOpenTo.setMinutes(+todayLocationHours.openTo.slice(3, 5));

  if (
    date.getTime() <= locationOpenFrom.getTime() ||
    date.getTime() > locationOpenTo.getTime()
  ) {
    return false;
  }

  return true;
};
