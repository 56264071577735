import {
  Box,
  CloseButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import { useAddToHomescreenPrompt } from "../hooks/AddToHomescreenPrompt";

const InstallPrompt = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [promptable, promptToInstall, isInstalled] = useAddToHomescreenPrompt();

  const cookies = new Cookies();
  const cookieExpiryDate = new Date();
  cookieExpiryDate.setMonth(cookieExpiryDate.getMonth() + 1);

  useEffect(() => {
    onOpen();
  }, []);

  if (!promptable || isInstalled) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        cookies.set("pwaPrompted", true, {
          path: "/",
          expires: cookieExpiryDate,
        });
        onClose();
      }}
      closeOnOverlayClick={true}
    >
      <ModalOverlay />
      <ModalContent
        position="fixed"
        bottom="-10"
        zIndex={40}
        fontSize="md"
        rounded="lg"
        backgroundColor="sumthindumplin.pink"
        textAlign="center"
        width="80%"
      >
        <CloseButton
          right="0"
          position="absolute"
          onClick={onClose}
          zIndex={55}
        />
        <Box zIndex={25} p="2" onClick={promptToInstall}>
          <Text fontWeight="extrabold">
            Click{" "}
            <Text as="span" textDecoration="underline">
              here
            </Text>{" "}
            to install app to
            <Text> homescreen!</Text>
          </Text>
        </Box>
        <Box
          zIndex={20}
          position="absolute"
          left="0"
          right="0"
          mx="auto"
          bottom="-2"
          rounded="sm"
          width="8"
          height="8"
          background="sumthindumplin.pink"
          p="3"
          transform="rotate(45deg)"
        />
      </ModalContent>
    </Modal>
  );
};

export default InstallPrompt;
