import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import InstallPrompt from "./InstallPrompt";
import IosInstructionsPrompt from "./IosIstructionsPrompt";

const PwaPrompt = () => {
  const [pwaPrompted, setPwaPrompted] = useState(false);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const cookies = new Cookies();

  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setPwaPrompted(cookies.get("pwaPrompted"));
  }, [cookies.get("pwaPrompted")]);

  // Don't show any prompts on desktop
  if (width > 768 || pwaPrompted) return null;

  if (isIos()) {
    return <IosInstructionsPrompt />;
  } else {
    return <InstallPrompt />;
  }
};

export default PwaPrompt;
