import { Box } from "@chakra-ui/react";
import { FC } from "react";
import { Fade } from "react-awesome-reveal";
import { useSelector } from "react-redux";
import { Order, Product } from "../../interfaces";
import ProductPreview from "../ProductPreview";

interface DisplayProductsProps {
  products: Product[];
  tabIndex?: number;
  onlyAddons?: boolean;
}

const DisplayProducts: FC<DisplayProductsProps> = ({
  products,
  tabIndex,
  onlyAddons,
}) => {
  const categories = ["Food", "Drinks", "Offers"];
  const order: Order = useSelector((state: any) => state.order);
  const cart = useSelector((state: any) => state.order.cart);

  const isProductOutOfStock = (productId: number) => {
    if (!order.location) return;
    const productOutOfStock = order.location.out_of_stock_products.data.find(
      (product: Product) => product.id === productId
    );

    if (!productOutOfStock) return false;
    return true;
  };

  return (
    <Box>
      {products.map((product: Product, i: number) => {
        if (product.expiresAt) {
          const date = new Date();
          const expiresAt = new Date(product.expiresAt);
          if (date > expiresAt) return null;
        }

        if (
          ((tabIndex !== undefined &&
            product.category === categories[tabIndex] &&
            !onlyAddons) ||
            (onlyAddons && !product.variationSelection)) &&
          !isProductOutOfStock(product.id)
        )
          return (
            <Fade>
              <ProductPreview
                key={i}
                product={product}
                previewVariation={onlyAddons ? "addonPopup" : "regular"}
              />
            </Fade>
          );

        return null;
      })}
    </Box>
  );
};

export default DisplayProducts;
