export interface Product {
  id: number;
  name: string;
  description: string;
  image: {
    data: {
      attributes: {
        url: string;
      };
    };
  };
  price: number;
  category: "Food" | "Drinks" | "Offers";
  expiresAt?: string;
  variationSelection: boolean;
}

export enum SauceOptions {
  "Sumthin Chilli (🔥, Peanuts*)",
  "Sriracha Mayo (🔥🔥)",
  "Sumthin Sesame",
  "Garlic Vinegar",
  "Sriracha (🔥🔥🔥)",
}

export interface CartProduct extends Product {
  initialPrice: number;
  cartItemId?: number;
  servingSize?: "regular" |"medium"| "large";
  sauce?: SauceOptions;
  notes?: string;
  quantity: number;
  extraSauce?: SauceOptions;
}

export interface Customer {
  name: string;
  email: string;
  phone: string;
  pickupTime: string;
}
export interface Order extends Customer {
  total: number;
  location?: Location;
  cart: CartProduct[];
}

export interface LocationHourEntry {
  id: number;
  day: string;
  openFrom?: string;
  openTo?: string;
  closed: boolean;
}

export interface Location {
  id: number;
  name: string;
  gstNumber: string;
  printerId?: number;
  out_of_stock_products: {
    data: Product[];
  };
  locationHours: LocationHourEntry[];
}
