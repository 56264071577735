import { Box, Button, Spinner, Text } from "@chakra-ui/react";
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  removeAllProducts,
  removeProduct,
  updateLocation,
  updateTotal
} from "../app/orderSlice";
import CartFooter from "../components/CartFooter";
import { isLocationOpen } from "../components/isLocationOpen";
import ProductPreview from "../components/ProductPreview";
import { CartProduct, Location, Product } from "../interfaces";
import BaseLayout from "../layouts/BaseLayout";

const Cart = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const cart = useSelector((state: any) => state.order.cart);
  const order = useSelector((state: any) => state.order);
  const [total, setTotal] = useState(0);

  const calculateTotalQuantity = () => {
    let totalQuantity = 0;
    cart.forEach((product: CartProduct) => {
      totalQuantity += product.quantity;
    });
    return totalQuantity;
  };

  const calculateTotal = () => {
    let productTotal = 0;
    cart.forEach((product: CartProduct) => {
      productTotal += product.price * product.quantity;
    });

    setTotal(productTotal);
    return productTotal;
  };

  const getLocations = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}strapi/locations`
    );
    return res?.data;
  };

  const {
    data: locations,
    isLoading: isLocationsLoading,
    error: isLocationsError,
  } = useQuery(["locations"], getLocations);

  const checkServices = async () => {
    try {
      const { data: isServicesValid } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}printnode/valid?printerId=${order.location.printerId}`
      );
      if (!isServicesValid) return false;
    } catch (err) {
      return false;
    }

    return true;
  };

  const checkProductsInStock = () => {
    const selectedLocation = locations.find(
      (l: Location) => l.id === order.location.id
    );

    let removedProducts = false;
    for (const product of order.cart) {
      const productOutOfStock =
        selectedLocation.out_of_stock_products.data.find(
          (p: Product) => p.id === product.id
        );

      if (productOutOfStock) {
        dispatch(removeProduct(product.cartItemId));
        removedProducts = true;
      }
    }

    return removedProducts;
  };

  const checkLocationOpen = () => {
    const selectedLocation = locations.find(
      (l: Location) => l.id === order.location.id
    );
    const locationOpenRes = isLocationOpen(selectedLocation);
    if (!locationOpenRes) {
      dispatch(updateLocation({ undefined }));
      return false;
    }
    return true;
  };

  const checkPickupTimeValid = () => {
    const currentTime = new Date();
    const pickupTime = new Date();
    pickupTime.setSeconds(0);
    pickupTime.setHours(
      order.location.locationHours[currentTime.getDay() === 0 ? 6 : currentTime.getDay() - 1].openTo.slice(0, 2)
    );
    pickupTime.setMinutes(
      order.location.locationHours[currentTime.getDay() === 0 ? 6 : currentTime.getDay() - 1].openTo.slice(
        3,
        5
      ) - 5
    );

    if (pickupTime < currentTime) return false;
    return true;
  };

  const submitOrder = async () => {
    setIsLoading(true);

    // Get the latest location data in case hours/stock has changed
    await getLocations();

    // Make sure the location they are trying to order from is open
    const locationOpen = checkLocationOpen();
    if (!locationOpen)
      throw new Error(
        "The location you were trying to order from is now closed - Please go back and select another store"
      );

    const pickupTimeValid = checkPickupTimeValid();
    if (!pickupTimeValid)
      throw new Error(
        "The time you have selected has now passed, please select a future pickup time, or now"
      );

    // Make sure the products in cart aren't out of stock at location
    const productsOutOfStock = checkProductsInStock();
    if (productsOutOfStock)
      throw new Error(
        "1 or more items in your cart are currently out of stock at this location - They have been removed from cart"
      );

    // Make sure location has a valid printerID
    const servicesValid = await checkServices();
    if (!servicesValid)
      throw new Error(
        "Error with ordering systems, please contact hello@sumthindumplin.co.nz"
      );

    // Creates order and recieves windcave payment session
    const windcaveSession = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}order`,
      order
    );

    // Redirects window to windcave payment session
    window.location.href = windcaveSession.data.links[1].href;
    setIsLoading(false);
  };

  const { mutate, isSuccess, isIdle } = useMutation(submitOrder, {
    onError: (e: Error | AxiosError) => {
      setIsLoading(false);
      if (axios.isAxiosError(e)) {
        return alert(
          "Error submitting - please contact hello@sumthingdumpling.co.nz"
        );
      }
      return alert(e);
    },
  });

  useEffect(() => {
    dispatch(updateTotal({ value: calculateTotal() }));
  }, [cart]);

  if (isLoading || isLocationsLoading)
    return (
      <BaseLayout>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          w="full"
          h="60vh"
        >
          <Spinner size="xl" />
        </Box>
      </BaseLayout>
    );

  return (
    <Box>
      <BaseLayout>
        <Box display="flex">
          <Text fontSize="2xl" fontWeight="bold">
            Cart: {calculateTotalQuantity()} items
          </Text>
          <Button
            variant="secondary"
            ml="auto"
            py="4"
            fontSize="xs"
            size="sm"
            isDisabled={!cart.length}
            onClick={() => dispatch(removeAllProducts())}
          >
            Clear Cart
          </Button>
        </Box>

        <Box
          display="flex"
          flexDir="column"
          gap="1"
          my="3"
          fontSize="sm"
          fontWeight="bold"
        >
          <Text>
            Customer Name:{" "}
            <Text fontWeight="normal" as="span">
              {order?.name}
            </Text>
          </Text>
          <Text>
            Customer Email:{" "}
            <Text fontWeight="normal" as="span">
              {order?.email}
            </Text>
          </Text>
          <Text>
            Customer Phone:{" "}
            <Text fontWeight="normal" as="span">
              {order?.phone}
            </Text>
          </Text>
          <Text>
            Order Pickup Time:{" "}
            <Text fontWeight="normal" as="span">
              {order?.pickupTime}
            </Text>
          </Text>
          <Text>
            Order Location:{" "}
            <Text fontWeight="normal" as="span">
              {order?.location?.name}
            </Text>
          </Text>
        </Box>

        <Box pb="24">
          {cart.map((cartProduct: CartProduct, i: number) => {
            return (
              <ProductPreview
                key={i}
                previewVariation="cart"
                product={cartProduct}
              />
            );
          })}
        </Box>
        <br />

        <Text mb="1">Sub Total: ${(total / 1.1).toFixed(2)}</Text>
        <Text mb="2">GST: ${(total / 11).toFixed(2)}</Text>
        <Box backgroundColor="sumthindumplin.red" h="1.5px" w="full" />
        <Text fontSize="20px" my="2" mb="5" pb="20">
          Total Amount:{" "}
          <Text fontWeight="bold" as="span">
            ${total.toFixed(2)}
          </Text>
        </Text>
      </BaseLayout>
      <CartFooter handleSubmit={mutate} />
    </Box>
  );
};

export default Cart;
