import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateCustomer } from "../app/orderSlice";
import BaseLayout from "../layouts/BaseLayout";

const customerDetailFields = ["name", "email", "phone", "pickupTime"];

const CustomerDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const order = useSelector((state: any) => state.order);

  const isFormValid = () => {
    if (!order.name || !order.email || !order.phone) {
      alert("Please fill in all fields");
      return false;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(order.email)) {
      alert("Please enter a valid email address");
      return false;
    }

    return true;
  };

  const roundToNearest15 = (date = new Date()) => {
    const ms = 1000 * 60 * 15;
    return new Date(Math.ceil(date.getTime() / ms) * ms);
  };

  const getOrderTimes = () => {
    const timeOptions = [];
    let currentTime = new Date();
    let closeTime = new Date();
    closeTime.setSeconds(0);
    closeTime.setHours(
      order.location.locationHours[currentTime.getDay() === 0 ? 6 : currentTime.getDay() - 1].openTo.slice(0, 2)
    );
    closeTime.setMinutes(
      order.location.locationHours[currentTime.getDay() === 0 ? 6 : currentTime.getDay() - 1].openTo.slice(
        3,
        5
      ) - 5
    );

    while (currentTime < closeTime) {
      currentTime = new Date(currentTime.getTime() + 15 * 60000);
      currentTime = roundToNearest15(currentTime);
      timeOptions.push(currentTime);
    }

    return timeOptions;
  };

  const handleSubmit = () => {
    if (isFormValid()) navigate("/cart");
  };

  return (
    <BaseLayout>
      <Text fontWeight="bold" fontSize="2xl" mt="5">
        Customer Details
      </Text>
      <FormControl isRequired>
        {customerDetailFields.map((customerDetailField, i) => {
          if (customerDetailField === "pickupTime")
            return (
              <Box key={i} my="5">
                <FormLabel fontWeight="bold">
                  {customerDetailField
                    .replace(/([A-Z])/g, " $1")
                    .charAt(0)
                    .toUpperCase() +
                    customerDetailField.replace(/([A-Z])/g, " $1").slice(1)}
                  :
                </FormLabel>
                <Select
                  rounded="xl"
                  borderColor="sumthindumplin.red"
                  borderWidth="1.5px"
                  color="black"
                  value={order[customerDetailField]}
                  onChange={(e) => {
                    const value = e.target.value;
                    dispatch(updateCustomer({ customerDetailField, value }));
                  }}
                >
                  <option value="now">Now</option>
                  {getOrderTimes().map((orderTime, i) => {
                    return (
                      <option
                        key={i}
                        value={`${orderTime.getHours()}:${
                          orderTime.getMinutes() < 10 ? "0" : ""
                        }${orderTime.getMinutes()}`}
                      >
                        {orderTime.getHours()}:
                        {orderTime.getMinutes() < 10 ? "0" : ""}
                        {orderTime.getMinutes()}
                      </option>
                    );
                  })}
                </Select>
              </Box>
            );

          return (
            <Box key={i} my="5">
              <FormLabel fontWeight="bold">
                {customerDetailField
                  .replace(/([A-Z])/g, " $1")
                  .charAt(0)
                  .toUpperCase() +
                  customerDetailField.replace(/([A-Z])/g, " $1").slice(1)}
                :
              </FormLabel>
              <Input
                rounded="xl"
                borderColor="sumthindumplin.red"
                borderWidth="1.5px"
                color="black"
                value={order[customerDetailField]}
                onChange={(e) => {
                  const value = e.target.value;
                  dispatch(updateCustomer({ customerDetailField, value }));
                }}
              />
            </Box>
          );
        })}
        {/* <LocationSelect locations={locations} /> */}
      </FormControl>

      <Box display="flex" gap="3" my="5" mx="auto">
        <Button variant="secondary" pl="1.5" onClick={() => navigate("/")}>
          <ChevronLeftIcon mr="auto" mt="0.5" h="5" w="6" />
          Back
        </Button>

        <Button
          variant="accent"
          pr="1.5"
          onClick={handleSubmit}
          isDisabled={!order.name || !order.email || !order.phone}
        >
          Next
          <ChevronRightIcon ml="auto" mt="0.5" h="5" w="6" color="white" />
        </Button>
      </Box>
    </BaseLayout>
  );
};

export default CustomerDetails;
