import { Box } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { ReactComponent as HeaderLogo } from "../images/header-logo.svg";
interface BaseLayoutProps {
  children: ReactNode | ReactNode[];
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  return (
    <Box
      display="flex"
      flexDir="column"
      mx="auto"
      maxW="container.md"
      px="5"
      overflowY="auto"
    >
      <Box px="8" pt="10" pb="5" ml="auto" mr="auto">
        <HeaderLogo />
      </Box>
      {children}
    </Box>
  );
};

export default BaseLayout;
