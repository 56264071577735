import { Box, Button, Text, useDisclosure } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ShoppingBag } from "../images/shopping-bag.svg";
import { CartProduct } from "../interfaces";

const CartPreview: FC<{ onAddonOpen: () => void }> = ({ onAddonOpen }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cart: CartProduct[] = useSelector((state: any) => state.order.cart);

  const [priceTotal, setPriceTotal] = useState(0);

  const calculateTotal = () => {
    let productTotal = 0;
    cart.forEach((product: CartProduct) => {
      productTotal += product.price * product.quantity;
    });

    setPriceTotal(productTotal);
    return productTotal;
  };

  useEffect(() => {
    calculateTotal();
  }, [cart]);

  return (
    <Box
      backgroundColor="sumthindumplin.darkpink"
      display="flex"
      justifyItems="center"
      alignItems="center"
      p={[5, 5, 5]}
      position="fixed"
      bottom="0"
      borderTopWidth="1.5px"
      borderTopColor="sumthindumplin.red"
      w="full"
      fontSize="md"
      fontWeight="extrabold"
    >
      <Text display="flex">
        <Text>Total Amount: ${priceTotal.toFixed(2)}</Text>
      </Text>

      <Button
        variant="accent"
        fontSize="sm"
        h="10"
        ml="auto"
        onClick={() => onAddonOpen()}
        isDisabled={!cart.length}
        fontWeight="extrabold"
      >
        <Text mt="0.5">Go To Checkout</Text>
        <Box pl="2.5">
          <ShoppingBag
            height="17"
            width="17"
            color={!cart.length ? "sumthindumplin.gray.700" : "white"}
          />
        </Box>
      </Button>
    </Box>
  );
};

export default CartPreview;
