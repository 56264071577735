import { useSelector } from "react-redux";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import "./App.css";
import "./index.css";
import Order from "./pages/1_Order";
import CustomerDetails from "./pages/2_CustomerDetails";
import Cart from "./pages/3_Cart";
import SplashPage from "./pages/4_SplashPage";
import MascotResultPage from "./pages/MascotResultPage";

function App() {
  const order = useSelector((state: any) => state.order);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Order />,
    },
    {
      path: "/customer-details",
      element: order.cart.length ? <CustomerDetails /> : <Navigate to="/" />,
    },
    {
      path: "/cart",
      element:
        order.cart.length &&
        order.email &&
        order.phone &&
        order.name &&
        order?.location?.name ? (
          <Cart />
        ) : (
          <Navigate to="/" />
        ),
    },
    {
      path: "/result",
      element: <SplashPage />,
    },
    /*{
      path: "/chillithedumplin",
      element: <MascotResultPage />,
    },*/
  ]);

  return <RouterProvider router={router} />;
}

export default App;
